import React from 'react';

import { Flex } from '@chakra-ui/layout';

import { Iframe } from '../atoms/iframe';
import { PanelHeader } from '../atoms/panel-header';

type Props = {
  id: string;
  url: string;
  name: string;
  isFull: boolean;
};
const IframePanel: React.FC<Props> = ({
  id,
  url,
  name,
  isFull = false,
}: Props) => {
  return (
    <Flex
      boxShadow="xl"
      bg="#eee"
      minH="100%"
      minW="100%"
      h="100%"
      w="100%"
      maxH="100%"
      maxW="100%"
      flexDir="column"
      paddingTop={isFull ? '3' : '0'}
      paddingLeft={isFull ? '3' : '0'}
      paddingRight={isFull ? '3' : '0'}
    >
      <PanelHeader id={id} />
      <Iframe url={url} name={name} isFull={isFull} />
    </Flex>
  );
};

export { IframePanel };
